<template>
  <div class="reports">
    <!-- Registration Reports -->
    <div v-if="regoTableData.length > 0">
      <el-table
        :data="regoTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column prop="name" label="Registration Reports"></el-table-column>
      </el-table>
    </div>

    <!-- Competition Management Reports -->
    <div v-if="compManagementTableData.length > 0">
      <el-table
        :data="compManagementTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column prop="name" label="Competition Management Reports"></el-table-column>
      </el-table>
    </div>

    <!-- Financial Reports -->
    <div v-if="financialTableData.length > 0">
      <el-table
        :data="financialTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column prop="name" label="Financial Reports"></el-table-column>
      </el-table>
    </div>

    <!-- Legacy Competition Management Reports -->

    <div v-if="legayCompManagementTableData.length > 0">
      <el-table
        :data="legayCompManagementTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column
          prop="name"
          label="Legacy Competition Management Reports"
        ></el-table-column>
      </el-table>
    </div>

    <div v-if="memberTableData.length > 0">
      <el-table
        :data="memberTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column prop="name" label="Member Reports"></el-table-column>
      </el-table>
    </div>

    <!-- active exchange reports -->
    <!-- <div v-if="activeExchangeTableData.length > 0">
      <el-table
        :data="activeExchangeTableData"
        border
        highlight-current-row
        @current-change="handleChange"
        class="reports-table"
      >
        <el-table-column prop="name" label="ActiveXchange Reports"></el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import { roles, nationals } from "../../utils/constants";

export default {
  name: "Reports",
  data() {
    return {
      regoTableData: [],
      compManagementTableData: [],
      legayCompManagementTableData: [],
      financialTableData: [],
      memberTableData: [],
      activeExchangeTableData: [],
    };
  },
  mounted() {
    const activeRoleType = this.$store.getters["user/activeRole"].type;
    const national = this.$store.getters["user/activeRole"].national_id;
    const permissions = this.$store.getters["user/getPermissions"];
    const filterByRoles = (items = []) => {
          return _.sortBy(
            items.filter(item => {
              if (item?.isAllowed !== undefined && item.isAllowed === true) {
                  return false;
              }
              return (!item.nationals || _.includes(item.nationals, national)) &&
                _.includes(item.roles, activeRoleType);
            }),
            ["name"]
          );
      };

    const superToClubAdminRoles = [
      roles.superAdmin,
      roles.stateAdmin,
      roles.regionAdmin,
      roles.associationAdmin,
      roles.clubAdmin,
    ];
    const superToAssocAdminRoles = [
      roles.superAdmin,
      roles.stateAdmin,
      roles.regionAdmin,
      roles.associationAdmin,
    ];
    const allProgramAdminRoles = [
      roles.programAdmin,
      roles.gamedevregionAdmin,
      roles.clubProgramAdmin,
    ];
    const allRoles = superToClubAdminRoles.concat(allProgramAdminRoles);

    // Registration reports
    this.regoTableData = filterByRoles([
      {
        name: "Program Registration Report",
        type: "program-rego",
        roles: allProgramAdminRoles,
      },
      {
        name: "Expression of Interest Report",
        type: "expression-interest",
        roles: allRoles,
      },
      {
        name: "Registration Detail Report",
        type: "rego-detail",
        roles: superToClubAdminRoles,
        isAllowed: permissions?.teamRegPermAllow === true ? false : true,

      },
      {
        name: "Program Registration Payment Detail Report",
        type: "program-rego-payment-detail",
        roles: [roles.gamedevregionAdmin, roles.programAdmin, roles.clubProgramAdmin],
      },
      {
        name: "Registration Payment Detail Report",
        type: "rego-payment-detail",
        roles: superToClubAdminRoles,
      },
      // {
      //   name: "TFA Registration Payment Detail Report",
      //   type: "tfa-rego-payment-detail",
      //   nationals: [nationals.TFA],
      //   roles: superToClubAdminRoles,
      // },
      {
        name: "Historical Registration Report",
        type: "historical-rego",
        roles: superToClubAdminRoles,
      },
      {
        name: "Contact Report",
        type: "contact",
        roles: [roles.clubAdmin, roles.associationAdmin],
      },
      // {
      //   name: "Voucher Report",
      //   type: "voucher",
      //   roles: [roles.clubAdmin, roles.associationAdmin],
      // },
      {
        name: "18-Month Registration Report",
        type: "eighteen-month-registration",
        nationals: [nationals.NRL],
        roles: [roles.superAdmin, roles.stateAdmin, roles.regionAdmin, roles.associationAdmin],
      },
      {
        name: "Team Registration Report",
        type: "team-rego",
        nationals: [nationals.TFA],
        roles: [roles.superAdmin, roles.stateAdmin, roles.regionAdmin, roles.associationAdmin],
      },
      {
        name: "Pending Registration Report",
        type: "pending-rego",
        nationals: [nationals.NRL],
        roles: superToClubAdminRoles,
        isAllowed: permissions?.pendingRegistApp,
      },
      {
        name: "Program Pack Fulfilment Report",
        type: "programPack-rego",
        roles: [roles.superAdmin],
      },
      // {
      //   name: "Marketing Report (Club Rugby League)",
      //   type: "marketing-club",
      //   nationals: [nationals.NRL],
      //   roles: [roles.superAdmin],
      // },
      // {
      //   name: "Marketing Report (Programs)",
      //   type: "marketing-programs",
      //   nationals: [nationals.NRL],
      //   roles: [roles.superAdmin],
      // },
    ]);

    // Competition management reports
    this.compManagementTableData = filterByRoles([
      {
        name: "Entity Details Report",
        type: "entity-details",
        roles: superToClubAdminRoles,
      },
      {
        name: "Admin Users Report",
        type: "admin-users",
        roles: [...superToClubAdminRoles, roles.gamedevregionAdmin],
      },
      {
        name: "Clearance Permit Report",
        type: "clearance-permit",
        nationals: [nationals.NRL],
        roles: superToClubAdminRoles,
        isAllowed: permissions?.hideClearanceApp,
      },
      {
        name: "Team Sheets Report",
        type: "team-sheets",
        roles: [roles.clubAdmin, roles.associationAdmin],
      },
      {
        name: "Accreditation Report",
        type: "accreditation",
        nationals: [nationals.NRL],
        roles: [...superToClubAdminRoles, ...allProgramAdminRoles],
      },
      {
        name: "Matches Report",
        type: "matches",
        roles: [roles.associationAdmin],
      },
      // TODO add ladders to reports server
      // {
      //   name: "Ladders Report",
      //   type: "ladders",
      //   roles: superToAssocAdminRoles
      // }
      // Match Detail report available at Club level too
      {
        name: "Matches Detail Report",
        type: "matches-detail",
        roles: superToClubAdminRoles,
      },
      {
        name: "Matches Without Participants Report",
        type: "matches-noparticipants",
        roles: [roles.associationAdmin],
      },
      {
        name: "Dispensation Report",
        type: "dispensation",
        roles: [roles.associationAdmin, roles.clubAdmin],
        isAllowed: permissions?.eighteenMonthApp,
      },
      {
        name: "Match Stats Report",
        type: "match-stats",
        roles: [roles.associationAdmin, roles.clubAdmin],
      },
      {
        name: "Competition Report ",
        type: "competition",
        roles: superToClubAdminRoles,
      },
      {
        name: "Player Stats Report",
        type: "player-stats",
        roles: superToClubAdminRoles,
      },
      {
        name: "Squad Report",
        type: "squad",
        roles: superToClubAdminRoles,
      },
      {
        name: "Squad Summary Report",
        type: "squad-summary",
        nationals: [nationals.TFA],
        roles: superToClubAdminRoles,
      },

      {
        name: "Squad Accreditation Report",
        type: "squad-accreditation",
        roles: superToClubAdminRoles,
      },
      {
        name: "Teams Report",
        type: "teams",
        roles: superToClubAdminRoles,
      },
      {
        name: "Unassigned Members Report",
        type: "unassigned-members",
        nationals: [nationals.NRL],
        roles: [roles.associationAdmin, roles.clubAdmin],
      },
      {
        name: "Tribunal Report",
        type: "tribunal",
        nationals: [nationals.NRL],
        roles: superToClubAdminRoles,
      },

      {
        name: "Injury Report",
        type: "injury",
        nationals: [nationals.NRL],
        roles: [
          ...superToClubAdminRoles,
          roles.gamedevregionAdmin,
          roles.programAdmin,
          roles.clubProgramAdmin,
        ],
      },
      {
        name: "Training Attendance Report",
        type: "training-attendance",
        roles: [...superToClubAdminRoles, ...allProgramAdminRoles],
      },
      {
        name: "Ladders Report",
        type: "ladders",
        roles: [roles.associationAdmin],
      },
      // {
      //   name: "Competition Cases - Injury Report Form",
      //   type: "competition-cases",
      //   roles: [...superToClubAdminRoles, ...allProgramAdminRoles]
      // }
    ]);

    // Legacy Competition management reports
    this.legayCompManagementTableData = filterByRoles([
      {
        name: "Historic Match Stats Report",
        type: "historic-match-stats",
        roles: superToClubAdminRoles,
      },
      {
        name: "Historic Teams Report",
        type: "historic-teams",
        roles: superToClubAdminRoles,
      },
      {
        name: "Historic Matches Detail Report",
        type: "historic-matches-detail",
        roles: superToClubAdminRoles,
      },
      {
        name: "Historic Competition Report ",
        type: "historic-competition",
        roles: superToClubAdminRoles,
      },
    ]);

    // Financial reports
    this.financialTableData = filterByRoles([
      {
        name: "Disbursements Report",
        type: "disbursements",
        roles: [...superToClubAdminRoles, roles.gamedevregionAdmin],
      },
      {
        name: "Saleable Items Report",
        type: "saleable-purchase",
        roles: [...superToClubAdminRoles, ...allProgramAdminRoles],
      },
      // {
      //   name: "Program Financial Report",
      //   type: "program-financial",
      //   roles: [roles.superAdmin],
      // },
      // {
      //   name: "Failed Transfers Report",
      //   type: "failed-transfers",
      //   roles: [roles.superAdmin],
      // },
      // {
      //   name: "Pin triggers",
      //   type: "pin-triggers",
      //   roles: [roles.superAdmin],
      // },
    ]);

    // Member reports
    this.memberTableData = filterByRoles([
      {
        name: "Duplicate Resolution Report",
        type: "duplicate-resolution",
        roles: [roles.superAdmin],
      },
      {
        name: "Unavailable Members Report",
        type: "unavailable-members",
        roles: [
          ...superToClubAdminRoles,
          roles.gamedevregionAdmin,
          roles.programAdmin,
          roles.clubProgramAdmin,
        ],
      },
    ]);

    // Active exchange reports
    this.activeExchangeTableData = filterByRoles([
      {
        name: "Participants and venues Report",
        type: "ax-participant-venues",
        roles: [roles.superAdmin],
      },
    ]);
  },
  methods: {
    handleChange(report) {
      if (report.type) this.$router.push({ name: `${report.type}-report` });
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  div:not(:first-child) {
    margin-top: 2rem;
  }
}
.reports-table {
  width: 50%;
  cursor: pointer;
}
</style>
